import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../../components/layout';
import { graphql } from 'gatsby';
import styles from './index.module.scss';
import HeroImageTakeover from '../../components/hero-image/hero-image-takeover';

const LandingPageApplicationSuccess = ({ data }) => {
  return (
    <Layout>
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML:
              'window.uetq = window.uetq || [];window.uetq.push("event", "landing_page_application_complete", {});',
          },
        ]}
      />
      <HeroImageTakeover fluid={data.file.childImageSharp.fluid}>
        <div className={`${styles.placementbox}`}>
          <div className={`${styles.placement}`}>
            <div className={`has-text-centered container ${styles.placement}`}>
              <div className={`${styles.thumbsup}`}>
                <h1
                  className={`title is-3 is-spaced ${styles.mainTitle} ${styles.heroHeading}`}
                >
                  Success!
                </h1>
                <p className={`content`}>
                  A message has been sent to your email address with information
                  about the next steps in the process.
                </p>
                <p className={`content`}>
                  A Trustaff career specialist will be in touch shortly. If you
                  wish to speak to someone right away, please call us at{' '}
                  <b>877.880.0346</b>.
                </p>
                <a className={`button is-info`} href="/" id="">
                  Home
                </a>{' '}
                <a
                  className={`button is-primary ${styles.spacing}`}
                  href="/jobs/"
                  id="jobs-cta"
                >
                  Search jobs
                </a>
                <img
                  src="//www.jobs2careers.com/conversion2.php?p=2196"
                  width="1"
                  height="1"
                  alt=" "
                  border="0"
                  className={`is-hidden`}
                />
              </div>
            </div>
          </div>
        </div>
      </HeroImageTakeover>
    </Layout>
  );
};

export default LandingPageApplicationSuccess;

export const pageQuery = graphql`
  {
    file(relativePath: { eq: "application-success/success_thanks.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2400) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
